html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
body {
  margin: 0;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}
audio,
canvas,
progress,
video {
  display: inline-block;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
progress {
  vertical-align: baseline;
}
template,
[hidden] {
  display: none;
}
a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
a:active,
a:hover {
  outline-width: 0;
}
abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}
b,
strong {
  font-weight: inherit;
}
b,
strong {
  font-weight: bolder;
}
dfn {
  font-style: italic;
}
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
mark {
  background-color: #ff0;
  color: #000;
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
img {
  border-style: none;
}
svg:not(:root) {
  overflow: hidden;
}
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}
figure {
  margin: 1em 40px;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
button,
input,
select,
textarea {
  font: inherit;
  margin: 0;
}
optgroup {
  font-weight: bold;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}
legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}
textarea {
  overflow: auto;
}
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
html {
  font-size: 18px;
}
@media (max-width: 2500px) {
  html {
    font-size: 15px;
  }
}
@media (max-width: 1440px) {
  html {
    font-size: 13px;
  }
}
@media (max-height: 600px) {
  html {
    font-size: 11px;
  }
}
@media (max-width: 768px) {
  html {
    font-size: 14px;
  }
}
@media (max-width: 768px) {
  html {
    font-size: 15px;
  }
}
@media (max-width: 425px) {
  html {
    font-size: 11px;
  }
}
html body {
  font-family: 'Ubuntu', sans-serif;
  color: #000;
}
html body h1,
html body h2,
html body h3,
html body h4,
html body h5,
html body h6,
html body p {
  margin: 0;
}
html body h1 {
  font-size: 2rem;
  text-transform: uppercase;
  font-weight: bold;
}
html body h2 {
  font-size: 1.333rem;
}
html body h6 {
  font-size: 0.7778rem;
}
html body p {
  font-size: 1rem;
}
html body .photo img {
  height: auto;
  max-width: 100%;
}
@media (min-width: 1024px) {
  html body .photo img {
    display: none;
  }
}
html body .item-wrapper {
  display: flex;
}
@media (min-width: 1100px) {
  html body .item-wrapper {
    height: 100vh;
  }
}
html body .item-wrapper .background-photo img {
  display: block;
  height: 100vh;
}
@media (max-width: 1100px) {
  html body .item-wrapper .background-photo img {
    display: none;
  }
}
@media (max-height: 750px) {
  html body .item-wrapper {
    height: auto;
  }
}
html body .item-wrapper .text-area {
  overflow-y: auto;
  flex: 1;
  padding: 50px 50px 22px 0;
}
@media (max-width: 1100px) {
  html body .item-wrapper .text-area {
    padding: 22px 22px 3.333rem 22px;
  }
}
@media (max-height: 750px) {
  html body .item-wrapper .text-area {
    height: auto;
  }
}
html body .item-wrapper .text-area .logo {
  align-self: flex-end;
  margin-bottom: 3.556rem;
}
html body .item-wrapper .text-area .logo img {
  width: 16.06rem;
}
html body .item-wrapper .text-area .logo.logo-funds {
  margin-bottom: 0.5rem;
}
html body .item-wrapper .text-area .content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
html body .item-wrapper .text-area .content-wrapper .header {
  margin-bottom: 3rem;
}
html body .item-wrapper .text-area .content-wrapper .header h1 {
  color: #303031;
  line-height: 2.5rem;
  margin-bottom: 1.056rem;
}
html body .item-wrapper .text-area .content-wrapper .header .title-funds {
  margin-bottom: 2.056rem;
}
html body .item-wrapper .text-area .content-wrapper .header h2 {
  color: #b58032;
  margin-bottom: 1.222rem;
}
html body .item-wrapper .text-area .content-wrapper .header hr {
  border-top: 4px solid #b58032;
  margin: 0;
}
html body .item-wrapper .text-area .content-wrapper .header.header-eu {
  margin-bottom: 1.333rem;
}
html body .item-wrapper .text-area .content-wrapper a {
  text-decoration: none;
}
html body .item-wrapper .text-area .content-wrapper a .download {
  margin-bottom: 2.333rem;
  display: flex;
  cursor: pointer;
}
html body .item-wrapper .text-area .content-wrapper a .download .download-img {
  padding-right: 27px;
}
html body .item-wrapper .text-area .content-wrapper a .download .download-img .svg {
  width: 3.778rem;
}
html body .item-wrapper .text-area .content-wrapper a .download .download-text p:nth-child(1) {
  color: #000;
  text-transform: uppercase;
  font-weight: bold;
}
html body .item-wrapper .text-area .content-wrapper a .download .download-text p:nth-child(2) {
  font-size: 0.8333rem;
  color: #b58032;
}
html body .item-wrapper .text-area .content-wrapper .contact {
  display: flex;
  font-weight: bold;
  margin-bottom: 2.333rem;
}
@media (max-width: 1024px) {
  html body .item-wrapper .text-area .content-wrapper .contact {
    flex-direction: column;
  }
}
html body .item-wrapper .text-area .content-wrapper .contact h2 {
  color: #b58032;
}
html body .item-wrapper .text-area .content-wrapper .contact p {
  margin-bottom: 1.056rem;
}
html body .item-wrapper .text-area .content-wrapper .contact .contact-mail {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
html body .item-wrapper .text-area .content-wrapper .contact .contact-number {
  margin-right: 8.33%;
}
@media (max-width: 1024px) {
  html body .item-wrapper .text-area .content-wrapper .contact .contact-number {
    margin-bottom: 1.056rem;
  }
}
html body .item-wrapper .text-area .content-wrapper .footer {
  margin-bottom: 2.333rem;
}
html body .item-wrapper .text-area .content-wrapper .footer h6 {
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 0.7222rem;
}
html body .item-wrapper .text-area .content-wrapper .footer ul {
  list-style-type: none;
  padding: 0;
  font-size: 0.7778rem;
  margin: 0;
}
html body .item-wrapper .text-area .content-wrapper .logo-content .more-info {
  font-size: 14px;
  font-weight: bold;
  color: #b58032;
}
html body .item-wrapper .text-area .content-wrapper .logo-content .logotyp {
  margin-top: 1.666rem;
}
html body .item-wrapper .text-area .content-wrapper .logo-content .logotyp .img:nth-child(1) {
  padding-bottom: 10px;
}
html body .item-wrapper .text-area .content-wrapper .logo-content .logotyp.logo-b {
  margin-top: 0;
}
html body .item-wrapper .text-area .content-wrapper .content {
  margin-top: 0rem;
  margin-bottom: 0.5rem;
}
html body .item-wrapper .text-area .content-wrapper .content .margin-bottom {
  margin-bottom: 2.333rem;
}
@media (min-width: 1024px) and (max-width: 1270px) {
  html body .item-wrapper .text-area .content-wrapper .content span {
    font-size: 12px;
  }
}
html body .item-wrapper .text-area .content-wrapper .content .project {
  margin-top: 2.333rem;
  margin-bottom: 2.333rem;
}
html body .item-wrapper .text-area .content-wrapper .content .project .project-title {
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 1.333rem;
  line-height: 18px;
}
html body .item-wrapper .text-area .content-wrapper .content .description {
  font-size: 14px;
}
@media (min-width: 1024px) and (max-width: 1270px) {
  html body .item-wrapper .text-area .content-wrapper .content .description {
    font-size: 12px;
  }
}
html body .item-wrapper .text-area .content-wrapper .content .description .info {
  margin-bottom: 1rem;
}
html body .item-wrapper .text-area .content-wrapper .arrow-back {
  font-size: 14px;
  color: #b58032;
  margin-bottom: 1rem;
}
html body .item-wrapper .text-area .content-wrapper .arrow-back svg {
  width: 15px;
  height: 10px;
}
html body .item-wrapper .text-area .content-wrapper .arrow-back img {
  width: 14px;
}
html body .item-wrapper .text-area .content-wrapper .arrow-back span {
  margin-left: 4px;
  font-weight: bold;
}
html body .item-wrapper .text-area .content-wrapper .logotyp-b {
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  justify-content: space-between;
}
@media (min-width: 1024px) and (max-width: 1360px) {
  html body .item-wrapper .text-area .content-wrapper .logotyp-b {
    display: '';
  }
}
html body .item-wrapper .text-area .content-wrapper .logotyp-b .img-b:nth-child(1) {
  margin-top: 2%;
  height: 141px;
}
@media (min-width: 1024px) and (max-width: 1360px) {
  html body .item-wrapper .text-area .content-wrapper .logotyp-b .img-b:nth-child(1) {
    width: 48%;
    height: 89%;
    margin-top: 0;
  }
}
@media (max-width: 510px) {
  html body .item-wrapper .text-area .content-wrapper .logotyp-b .img-b:nth-child(1) {
    width: 48%;
    height: 89%;
    margin-top: 0;
  }
}
@media (min-width: 1024px) and (max-width: 1360px) {
  html body .item-wrapper .text-area .content-wrapper .logotyp-b .img-b:nth-child(2) {
    width: 54%;
  }
}
@media (max-width: 510px) {
  html body .item-wrapper .text-area .content-wrapper .logotyp-b .img-b:nth-child(2) {
    width: 54%;
  }
}
html .copyright {
  color: #b58032;
  font-size: 11px;
  text-align: right;
}
html .copyright-index {
  padding-top: 60px;
}
