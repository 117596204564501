html
    font-size: 18px
    @media(max-width:2500px)
        font-size: 15px   
    @media(max-width:1440px)
        font-size: 13px
    @media(max-height:600px)
        font-size: 11px
    @media(max-width:768px)
        font-size: 14px
    @media(max-width:768px)
        font-size: 15px
    @media(max-width:425px)
        font-size: 11px
    body
        font-family: 'Ubuntu', sans-serif
        color: black

        h1,h2,h3,h4,h5,h6,p
            margin 0
    
        h1
            font-size: 2rem // 36px
            text-transform: uppercase
            font-weight: bold
        h2
            font-size: 1.333rem // 24px
        h6
            font-size:  0.7778rem // 14px
        p
            font-size: 1rem

        .photo
            img
                height: auto
                max-width: 100%
                @media(min-width: 1024px)
                    display: none

        .item-wrapper
            display: flex
            @media(min-width:1100px)
                height: 100vh	

            .background-photo
                img
                    display: block
                    height: 100vh
                    @media(max-width:1100px)
                        display:none
                           
            @media(max-height:750px)
                height: auto
            .text-area
                overflow-y: auto
                flex: 1              
                padding: 50px 50px 22px 0 // 120px
                @media(min-width:1440px)
                   // padding: 140px 22px 3.333rem 22px
                @media(max-width:1100px)
                    padding: 22px 22px 3.333rem 22px
                @media(max-height:750px)
                    height: auto
                .logo
                    align-self: flex-end
                    margin-bottom: 3.556rem  //100px                    
 

                    img
                        width: 16.06rem

                    &.logo-funds
                        margin-bottom: 0.5rem

                .content-wrapper
                    display: flex
                    flex-direction: column
                    justify-content: center
                    .header
                        margin-bottom: 3rem //60px
                        h1
                            color: like-black
                            line-height: 2.5rem
                            margin-bottom: 1.056rem // 19 px
                        .title-funds
                            margin-bottom: 2.056rem
                        h2
                            color: gold
                            margin-bottom: 1.222rem
                        hr
                            border-top: 4px solid gold
                            margin: 0
                        &.header-eu
                            margin-bottom: 1.333rem 
                         
                    a
                        text-decoration: none               
                        .download
                            margin-bottom: 2.333rem //60px                        
                            display: flex
                            cursor: pointer
                            .download-img
                                padding-right: 27px
                                .svg
                                    width: 3.778rem // 68px   
                            .download-text                                             
                                p
                                    &:nth-child(1)
                                        color: black
                                        text-transform: uppercase
                                        font-weight: bold
                                    &:nth-child(2)
                                        font-size: 0.8333rem
                                        color: gold
                           

                    .contact
                        display: flex
                        font-weight: bold
                        margin-bottom: 2.333rem //60px                        
                        @media(max-width:1024px)
                            flex-direction: column
                        h2
                            color: gold
                        p 
                            margin-bottom: 1.056rem // 19 px

                        .contact-mail
                            display: flex
                            flex-direction: column
                            justify-content: flex-end 
                        .contact-number
                            margin-right: 8.33%                        
                            @media(max-width:1024px)
                                margin-bottom: 1.056rem  
                                         
                    .footer
                        margin-bottom: 2.333rem
                        h6
                            text-transform: uppercase
                            font-weight: bold
                            margin-bottom: 0.7222rem
                        ul
                            list-style-type: none
                            padding: 0
                            font-size:  0.7778rem // 14px
                            margin:0 

                    .logo-content
                        .more-info
                            font-size: 14px
                            font-weight: bold
                            color:#b58032
                        .logotyp
                            margin-top: 1.666rem
                            .img
                                &:nth-child(1)
                                    padding-bottom: 10px
                            &.logo-b
                                margin-top: 0
                    .content
                        margin-top:0rem
                        margin-bottom: 0.5rem

                        .margin-bottom
                            margin-bottom: 2.333rem

                        span
                            @media(min-width:1024px) and (max-width:1270px)
                                font-size: 12px
                        .project
                            margin-top:2.333rem
                            margin-bottom: 2.333rem
                            .project-title
                                font-size: 18px
                                font-weight: bold
                                text-transform: uppercase
                                margin-bottom: 1.333rem
                                line-height: 18px
                        .description
                            font-size: 14px
                            @media(min-width:1024px) and (max-width:1270px)
                                font-size: 12px
                            .info
                                margin-bottom: 1rem
                    .arrow-back
                        font-size: 14px
                        color:#b58032
                        margin-bottom: 1rem
                        svg 
                            width: 15px
                            height: 10px
                        img
                            width: 14px
                        span
                            margin-left: 4px
                            font-weight: bold
                    .logotyp-b
                        display:flex
                        display: -webkit-box;
                        display: -ms-flexbox;
                        justify-content: space-between
                        @media(min-width:1024px) and (max-width:1360px)
                            display: ''
                        .img-b
                            &:nth-child(1)
                                margin-top: 2%
                                height: 141px
                                @media(min-width:1024px) and (max-width:1360px)
                                    width: 48%
                                    height: 89%
                                    margin-top: 0
                                @media(max-width:510px)
                                    width: 48%
                                    height: 89%
                                    margin-top:0
                            &:nth-child(2)
                                @media(min-width:1024px) and (max-width:1360px)
                                    width: 54%
                                @media(max-width:510px)
                                    width: 54%
				.copyright
					color:#b58032
					font-size: 11px
					text-align: right 
				.copyright-index
					padding-top:60px
